export function makeId(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function computeScore(anket, stepIndexParam) {
    let step = anket.steps[stepIndexParam]
    let score = 0
    for (let q of step.questions) {
        if (!q.multiple && !q.typeText && q.answer != null) {
            score += q.options[q.answer].score
        } else if (q.multiple && q.answer != null) {
            for (let ao of q.answer) {
                score += q.options[ao].score
            }
        }
    }
    return score
}

export function getStepResult(anket, stepIndexParam) {
    let score = computeScore(anket, stepIndexParam)
    let stepResults = anket.steps[stepIndexParam].stepResults
    let index = 0
    for (let result of stepResults) {
        if (score <= result.threshold) {
            return {index: index, name: result.name}
        }
        index++
    }
    return {index: index - 1, name: stepResults[stepResults.length - 1].name}
}

export function getAnketResult(anket) {
    let res = anket.results
    for (let i = 0; i < anket.steps.length; i++) {
        res = res[getStepResult(anket, i).index]
    }
    return res
}


export function extractAnswers(anket) {
    let answers = []
    for (let step of anket.steps) {
        let stepAnswers = []
        for (let q of step.questions) {
            stepAnswers.push(q.answer)
            if (q.subQuestions != null) {
                for (let sq of q.subQuestions) {
                    if (ifShowSubQuestion(q, sq)) {
                        stepAnswers.push(sq.answer)
                    }
                }
            }
        }
        answers.push(stepAnswers)
    }
    return JSON.stringify(answers)
}

export function restoreAnswers(anket, answersStr) {
    let answers = JSON.parse(answersStr)
    for (let i = 0; i < anket.steps.length; i++) {
        let j = 0
        for (let q of anket.steps[i].questions) {
            q.answer = answers[i][j]
            j++
            if (q.subQuestions != null) {
                for (let sq of q.subQuestions) {
                    if (ifShowSubQuestion(q, sq)) {
                        sq.answer = answers[i][j]
                        j++
                    }
                }
            }
        }
    }
}

export function validateStep(anket, stepIndex) {
    for (let q of anket.steps[stepIndex].questions) {
        let r = validateQuestionAnswer(q)
        if (r) return r
        if (q.subQuestions != null) {
            for (let sq of q.subQuestions) {
                if (ifShowSubQuestion(q, sq)) {
                    r = validateQuestionAnswer(sq)
                    if (r) return r
                }
            }
        }
    }
    return true
}


export function validateOnlyMaxLengthInStep(anket, stepIndex) {
    for (let q of anket.steps[stepIndex].questions) {
        let r = validateOnlyMaxLengthQuestionAnswer(q)
        if (r) return r
        if (q.subQuestions != null) {
            for (let sq of q.subQuestions) {
                if (ifShowSubQuestion(q, sq)) {
                    r = validateOnlyMaxLengthQuestionAnswer(sq)
                    if (r) return r
                }
            }
        }
    }
    return true
}

function validateQuestionAnswer(q) {
    if (q.answer == null && !q.typeText) {
        return "Заполните все поля анкеты"
    }
    if (q.typeText && q.answer != null && q.answer.length > 1000) {
        return "Длина ответа на открытый вопрос не должна превышать 1000 символов"
    }
}

function validateOnlyMaxLengthQuestionAnswer(q) {
    if (q.typeText && q.answer != null && q.answer.length > 1000) {
        return "Длина ответа на открытый вопрос не должна превышать 1000 символов"
    }
}

export function ifShowSubQuestion(question, subQuestion) {
    if (!question.multiple)
        return question.answer != null && question.answer.toString() === subQuestion.ifOption
    else {
        if (question.answer == null || question.answer.length === 0)
            return false
        if (subQuestion.ifOption === "any")
            return true
        if (question.answer.toString() === subQuestion.ifOption ||
            question.answer.includes(+subQuestion.ifOption)) {
            return true
        }
        return false
    }
}

export function getExcelAnswersStr (anket, answersStr) {
    let res = []
    let answers = JSON.parse(answersStr)
    for (let i = 0; i < anket.steps.length; i++) {
        let j = 0
        for (let q of anket.steps[i].questions) {
            res.push(q.text + ': ' + humanifyAnswer(q, answers[i][j]))
            q.answer = answers[i][j]
            j++
            if (q.subQuestions != null) {
                for (let sq of q.subQuestions) {
                    if (ifShowSubQuestion(q, sq)) {
                        res.push(sq.text + ': ' + humanifyAnswer(sq, answers[i][j]))
                        j++
                    } else {
                        res.push('')
                    }
                }
            }
        }
    }
    return res
}

function humanifyAnswer (question, answer) {
    if (question.typeText) {
        return answer
    }
    if (Array.isArray(answer)) {
        let res = []
        for (let a of answer) {
            res.push(question.options[a].text)
        }
        return res.join('\r\n')
    } else {
        if(question.options[answer]==null) {
            console.log('shit')
            console.log(question)
            console.log(answer)
            return '-'
        }
        return question.options[answer].text
    }
}
