<template>
    <div>
      <div>
        <v-checkbox
            v-for="(option, optionIndex) in question.options"
            :key="'opt'+optionIndex"
            :label="option.text"
            @change="setValue(optionIndex, $event)"
        ></v-checkbox>
      </div>
      <div>
        {{ question.description }}
      </div>
    </div>
</template>

<script>
export default {
  name: "QuestionTwoSelect",
  props: ["question", "updatedCallback"],
  data: function () {
    return {
      resArray: []
    };
  },
  methods: {
    setValue(index, value) {
      if(value){
        this.addOptionAnswer(index)
      } else {
        this.removeOptionAnswer(index)
      }
      this.question.answer = this.resArray
      if(this.updatedCallback != null){this.updatedCallback()}
    },

    addOptionAnswer(index){
      this.resArray.push(index)
    },

    removeOptionAnswer(index){
      for(let i = 0; i < this.resArray.length; i++){
        if(this.resArray[i] === index)
          this.resArray.splice(i, 1)
      }
    }
  },
  beforeMount() {
    this.question.answer = [] //допускается пустой ответ
  }
}
</script>

<style scoped>

</style>
