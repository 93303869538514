<template>
    <div>
      <div>
        <v-select :items="selectItems" dense filled
                  item-text="text"
                  item-value="code"
                  label=" "
                  placeholder="Выберите ответ"
                  rounded @change="setValue($event)"></v-select>
      </div>
      <div>
        {{ question.description }}
      </div>
    </div>
</template>

<script>
export default {
  name: "QuestionComboSelect",
  props: ["question", "updatedCallback"],
  data: function () {
    return {
      selectItems: []
    };
  },
  methods: {
    setValue(val) {
      this.question.answer = val
      if(this.updatedCallback != null){this.updatedCallback()}
    }
  },
  beforeMount() {
    let opts = this.question.options
    for(let i = 0; i < opts.length; i++){
      this.selectItems.push({
        code: i, text: opts[i].text
      })
    }
  }
}
</script>

<style scoped>

</style>
