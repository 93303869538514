<template>
    <div>
      <div>
        <div class="radio-btn-group">
          <div class="radio">
            <input :id="'radio_kdie1_'+componentId" :name="'radio_kdie1_'+componentId" type="radio" @click="setValue(0)"/>
            <label :for="'radio_kdie1_'+componentId">{{question.options[0].text}}</label>
          </div>
          <div class="radio">
            <input :id="'radio_kdie2_'+componentId" :name="'radio_kdie1_'+componentId" type="radio" @click="setValue(1)"/>
            <label :for="'radio_kdie2_'+componentId">{{question.options[1].text}}</label>
          </div>
        </div>
      </div>
      <div>
        {{question.description}}
      </div>
    </div>
</template>

<script>
import {makeId} from "../modules/questions";

export default {
  name: "QuestionTwoSelect",
  props: ["question", "updatedCallback"],
  data() {
    return {componentId: null}
  },
  methods: {
    setValue(val){
      this.question.answer = val
      if(this.updatedCallback != null){this.updatedCallback()}
    }
  },
  beforeMount() {
    this.componentId = makeId(32)
  }
}
</script>

<style scoped>

</style>
