<template>
  <div>
    <div class="step-header"><span>Шаг {{ stepIndex + 1 }}.</span> {{ step.name }}</div>
    <template v-for="(question, questionIndex) in step.questions">
      <div class="form-row" :key="'qstn'+stepIndex+'.'+questionIndex">
        <label class="partitionbreak" v-if="question.partitionBreak != null && question.partitionBreak.length > 0">{{
            question.partitionBreak
          }}</label>
        <label v-if="question.text != null && question.text.length > 0">{{ noNumbers ? '' : (questionIndex + 1) + '.'}} {{
            question.text
          }}</label>
        <label v-if="question.subText != null && question.subText.length > 0" style="font-weight: normal">
          {{ question.subText }}</label>
        <QuestionTwoSelect
            v-if="!question.multiple && !question.typeText && question.options.length===2"
            :question="question"
            :updated-callback="questionValueUpdated"></QuestionTwoSelect>
        <QuestionThreeSelect
            v-if="!question.multiple && !question.typeText && question.options.length===3"
            :question="question"
            :updated-callback="questionValueUpdated"></QuestionThreeSelect>
        <QuestionComboSelect
            v-if="!question.multiple && !question.typeText && question.options.length>3 && !question.typeTenScore"
            :question="question"
            :updated-callback="questionValueUpdated"></QuestionComboSelect>
        <QuestionMultipleSelect
            v-if="question.multiple"
            :question="question"
            :updated-callback="questionValueUpdated"></QuestionMultipleSelect>
        <TextQuestion
            v-if="question.typeText"
            :question="question"
            :updated-callback="questionValueUpdated"/>
        <QuestionTenScore
            v-if="question.typeTenScore"
            :question="question"
            :updated-callback="questionValueUpdated"/>
        <div class="error"
             v-if="hasErrors && !question.typeText && question.answer == null"
             :key="'qstnf1'+stepIndex+'.'+questionIndex+'.'+subQuestionIndex">Необходимо ответить на вопрос</div>
      </div>
      <template v-for="(subQuestion, subQuestionIndex) in question.subQuestions">
        <div
            class="form-row"
            style="margin-top:-40px"
            v-if="cifShowSubQuestion(question, subQuestion)"
            :key="'qstn'+stepIndex+'.'+questionIndex+'.'+subQuestionIndex">
          <label style="font-weight: normal">{{ subQuestion.text }}</label>
          <QuestionTwoSelect
              v-if="!subQuestion.multiple && !subQuestion.typeText && subQuestion.options.length===2"
              :question="subQuestion"></QuestionTwoSelect>
          <QuestionThreeSelect
              v-if="!subQuestion.multiple && !subQuestion.typeText && subQuestion.options.length===3"
              :question="subQuestion"></QuestionThreeSelect>
          <QuestionComboSelect
              v-if="!subQuestion.multiple && !subQuestion.typeText && subQuestion.options.length>3 && !subQuestion.typeTenScore"
              :question="subQuestion"></QuestionComboSelect>
          <QuestionMultipleSelect
              v-if="subQuestion.multiple"
              :question="subQuestion"></QuestionMultipleSelect>
          <TextQuestion
              v-if="subQuestion.typeText"
              :question="subQuestion"
              :updated-callback="questionValueUpdated"/>
          <QuestionTenScore
              v-if="subQuestion.typeTenScore"
              :question="subQuestion"
              :updated-callback="questionValueUpdated"/>
<!--          <div class="error" :key="'qstnf'+stepIndex+'.'+questionIndex+'.'+subQuestionIndex">asdfasdf</div>-->
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import QuestionTwoSelect from "./QuestionTwoSelect";
import QuestionThreeSelect from "./QuestionThreeSelect";
import QuestionComboSelect from "./QuestionComboSelect";
import QuestionMultipleSelect from "./QuestionMultipleSelect";
import TextQuestion from "./TextQuestion";
import QuestionTenScore from "./QuestionTenScore";
import {ifShowSubQuestion} from "../modules/questions";


export default {
  name: "StepComponent",
  components: {
    TextQuestion,
    QuestionTwoSelect,
    QuestionThreeSelect,
    QuestionComboSelect,
    QuestionMultipleSelect,
    QuestionTenScore
  },
  props: ["step", "stepIndex", "noNumbers", "hasErrors"],
  methods: {
    questionValueUpdated() {
      this.$forceUpdate()
    },

    cifShowSubQuestion(q, sq){
      return ifShowSubQuestion(q, sq)
    }

  }
}
</script>

<style scoped>
.partitionbreak {
  font-weight: bold;
  font-size: 160%;
  margin-top: 110px
}
</style>
