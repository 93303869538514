<template>
  <div>
    <h3>Благодарим Вас за участие в опросе!</h3>

    <template v-if="showResult">
      <h3>Ваш результат</h3>

      <div class="results-text">
        <b>{{ resultText.name }}</b>
      </div>

      <div class="result-text-secondary">
        {{ resultText.description }}
        <br><br>Ваша анкета сохранена в базе данных и будет обработана в ближайшее время
<!--        Во время следущей беседы с сотрудником ЦНЗ сообщите ему, пожалуйста, что прошли анкетирование.-->
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "ResultComponent",
  components: {},
  props: ["resultText", "showResult"]
}
</script>

<style scoped>

</style>
