<template>
    <div>
      <div>
        <v-textarea style="margin-top:-10px" @change="setValue($event)"></v-textarea>
      </div>
      <div>
        {{question.description}}
      </div>
    </div>
</template>

<script>

export default {
  name: "TextQuestion",
  props: ["question", "updatedCallback"],
  data() {
    return {}
  },
  methods: {
    setValue(val){
      this.question.answer = val
      if(this.updatedCallback != null){this.updatedCallback()}
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped>

</style>
